import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
const TestimonialOne = () => {
    return (
        <div className="row">
            <div className="col-lg-12">
            
                <Tabs>
                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p>Am avut o comunicare foarte bună cu Roasoftware, au fost transparenti și au înteles nevoile noastre foarte precis. Vom lucra cu ei și în viitor.</p>
                            </div>
                            <div className="author-info">
                                <h6><span>Stefan G.</span></h6>
                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p>Am fost impresionati de nivelul de profesionalism de care Roasoftware a dat dovadă de-a lungul colaborării noastre. Recomand această firmă !</p>
                            </div>
                            <div className="author-info">
                                <h6><span>Marius A. </span> </h6>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p>Working with Roasoftware helped us transform the way that we run our business in digital. we are very satisfied with the collaboration</p>
                            </div>
                            <div className="author-info">
                                <h6><span>Ana M.</span></h6>
                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p>Our collaboration with Roasoftware was an excellent one. The website came out the way we wanted. Congratulations guys!</p>
                            </div>
                            <div className="author-info">
                                <h6><span>Razvan B.</span> </h6>
                            </div>
                        </div>
                    </TabPanel>
                    
                    <TabList className="testimonial-thumb-wrapper">
                        <Tab>
                            <div className="testimonial-thumbnai">
                                <div className="thumb">
                                    <img src="/assets/images/client/testimonial-1.jpg" alt="Testimonial Images"/>
                                </div>
                            </div>
                        </Tab>
                        <Tab>
                            <div className="testimonial-thumbnai">
                                <div className="thumb">
                                    <img src="/assets/images/client/testimonial-2.jpg" alt="Testimonial Images"/>
                                </div>
                            </div>
                        </Tab>
                        <Tab>
                            <div className="testimonial-thumbnai">
                                <div className="thumb">
                                    <img src="/assets/images/client/testimonial-3.jpg" alt="Testimonial Images"/>
                                </div>
                            </div>
                        </Tab>
                        <Tab>
                            <div className="testimonial-thumbnai">
                                <div className="thumb">
                                    <img src="/assets/images/client/testimonial-4.jpg" alt="Testimonial Images"/>
                                </div>
                            </div>
                        </Tab>
                       
                    </TabList>

                </Tabs>
            </div>
        </div>
    )
}

export default TestimonialOne
