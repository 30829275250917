import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'Cine suntem',
        description = 'Suntem o companie software cu e experientă de peste 10 ani în domeniul digital. Astăzi, construim, lansam și administrăm aplicatii web și mobile complexe mobile folosindu-ne de technologii precum GraphQL, React Native, Typescript sau Laravel framework. Solutiile noastre IT sunt rapide, scalabile, robuste și sigure. Mai exact, putem lucra pentru dvs. în proiecte precum: magazine online, platforme web complexe, software ERP/CRM sau site-uri de prezentare. Credem în parteneriate pe termen lung cu clientii noștri.';
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                    <div className="col-lg-12" style={{margin: "auto" }}>
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title"style={{textAlign:"center" }}>{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;