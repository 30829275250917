import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} || Your web and app development partener </title>
                    <meta name="description" content="We are helping businesses to transform and scale through state-of-the-art custom software and mobile app. We can work for you in projects such as: online stores, complex web platforms, ERP/CRM soft, or presentation websites." />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
