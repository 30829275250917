import React, { Component } from "react";

const ServiceList = [
    {
        icon: '01',
        title: 'Solutii software personalizate',
        description: 'Dezvoltarea de solutii software personalizate înseamnă că obtineti exact ceea ce doriti, și anume o solutie online sau offline personalizată care se potrivește perfect afacerii și nevoilor dvs.'
    },
    {
        icon: '02',
        title: 'Aplicatii mobile',
        description: 'Aplicatiile mobile sunt un „game changer” pentru multe companii în era digitală. Rularea aplicatiilor pe dispozitive inteligente oferă clientilor un acces mai mare la serviciile companiei.'
    },
    {
        icon: '03',
        title: 'Platforme de comert electronic',
        description: 'Când vine vorba de vânzări online, solutiile noastre de comert electronic B2C/B2B sunt perfecte pentru orice tip de afacere. Construim aplicatii web/mobile de comert electronic moderne și sigure.'
    },
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1"> 
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                        
                    ))}
                </div>
            </React.Fragment>
            
        )
    }
}
export default ServiceOne;