import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp,FiCpu,FiGlobe,FiLock ,FiShoppingCart,FiCode,FiAtSign,FiAirplay,FiTablet} from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


const ServiceList = [
    {
        icon: <FiCpu />,
        title: 'IOT Development',
        description: 'Vă putem ajuta să deblocați potențialul din Internet of Things pentru a vă îmbunătăți perspectiva afacerii dvs, reducând costurile de producție și a maximiza lanțurile valorice.'
    },
    {
        icon: <FiLayers />,
        title: 'UI/UX approch',
        description: ' Designerii noștri pot transforma orice idee în realitate creând pentru dvs. un produs digital personalizat, unic, care să pună în valoare brandul afacerii pe care o păstoriți.'
    },
    {
        icon: <FiUsers />,
        title: 'SaaS Development',
        description: 'Folosind strategiile SaaS (Software as a Services) suntem capabili să vă oferim, să vă securizăm și să vă menținem orice fel de produse digitale orientate către client.'
    },
    {
        icon: <FiMonitor />,
        title: 'Desktop development',
        description: 'Desktop Software implică crearea de aplicații care funcționează pe computere, chiar și în momentul în care acestea nu au o conexiune la internet. Putem crea aplicații desktop pentru orice distribuție de Linux.'
            },
    {
        icon: <FiGlobe />,
        title: 'Web based Development',
        description: 'O aplicație web poate  fi  accesată  printr-un browser web, cum ar fi Google Chrome, Safari sau Mozilla Firefox.  Vă putem ajuta în realizarea oricăror aplicații de acest tip.'
    },
    {
        icon: <FiLock />,
        title: 'Secure development',
        description: 'Securitatea web este o prioritate, și ținem cont de un set de reguli în fiecare pas al proiectului dvs, de la  proiectare și până la dezvoltare și operaționare. Centrul nostru de hosting este rapid, securizat, iar back-upurile sunt zilnice.'
    },
    
]
const ServiceList2 = [
    {
        icon: <FiAtSign />,
        title2: 'Store design',
        description2: 'Selectăm cele mai bune tehnologii pentru magazinul dvs. electronic, indiferent dacă sunteți un CTO bine versat în cele mai recente alegeri tehnologice sau un începător în ale tehnologiei, de la backend la dezvoltarea frontend, echipa noastră de consilieri va fi alături de dvs.'
    },
    {
        icon: <FiCode/>,
        title2: 'Theme build',
        description2: 'Dezvoltăm magazinul dvs. de la zero sau printr-un sistem de management al conținutului, cum ar fi Prestashop sau woocomerce'
    },
    {
        icon: <FiShoppingCart />,
        title2: 'API Development',
        description2: 'Integrăm API-uri pentru servicii conexe precum ar fi procesatorii de plăți, diferiții curieri, sau sisteme de conversie a valutei sau a limbii  platformei. '
    },
    
]
const ServiceList3 = [
    {
        icon: <FiTablet />,
        title3: 'React Native App',
        description3: 'Suntem capabili să construim și să lansăm aplicații mobile independente pentru platformele Android și iOS, folosindu-ne de React Native.'
    },
    {
        icon: <FiAirplay />,
        title3: 'FLUTTER APP ',
        description3: 'Flutter este un SDK complet (kit de dezvoltare software) lansat de Google în 2018. Oferă un arsenal complet pentru mediile Android și iOS, precum și pentru Mac, Windows, Linux și aplicații web! Dezvoltatorii Flutter construiesc dintr-o singură bază de cod folosind widget-uri, interfețe API, un sistem de vizualizare proprietar și alte elemente.'
    },
    
]
class Service extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Servicii' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Servicii'}   />
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Solutii software personalizate</h2>
                                    <p>Tehnologia este cheia pentru a construi companii eficiente, productive și profitabile în era digitală. Avem abilitătile și resursele pentru a construi software personalizat care să vă ajute atât în managementul afacerii dvs, cât și în relatia cu clientii.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="#">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Platforme de comert electronic</h2>
                                    <p>Ne propunem să deblocăm potentialul platformelor de comert electronic prin crearea unor aplicatii web & mobile distinse, care oferă securitate, rapiditate și scalabilitate. Echipa noastră este pasionată să creeze produse digitale orientate spre client folosind Laravel, Node js, React sau CMS ca Wordpress sau Prestashop.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList2.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                   
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title2}</h3>
                                                <p>{val.description2}</p>
                                            </div>
                                        </div>
                                
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Aplicatii mobile</h2>
                                    <p>Aplicatiile mobile sunt un „game changer” pentru multe companii în era digitală. Rularea aplicatiilor pe dispozitive inteligente oferă clientilor un acces mai mare la serviciile companiei. Smartphone-urile au devenit un terminal foarte comun în toate industriile și pietele. Este un fapt, tehnologiile fac viata mult mai ușoară. Când vine vorba de dezvoltarea de aplicatii mobile, este esential să întelegeti ce așteptări aveti de la aceasta. Cea mai bună solutie de dezvoltare a aplicatiilor mobile va permite afacerii dumneavoastră să-și extindă prezenta pe piată și să treacă înaintea concurentei.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ServiceList3.map( (val , i) => (
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title3}</h3>
                                                <p>{val.description3}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default Service;