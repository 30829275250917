import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";
import {Link} from "react-router-dom";
import { FiExternalLink } from "react-icons/fi";


const PortfolioList = [
    {
        image: 'image-1',
        title: 'Solutie comert elecotrnic pentru hub românesc',
        id:'1',
        visit:'https://saash.ro'
    },
    {
        image: 'image-2',
        title: 'Site personal  artist vizual',
        visit:'https://stefangrigoras.com'
    },
    {
        image: 'image-3',
        title: 'Platforma de booking ',
        visit:'https://travlocals.com'
    },
    {
        image: 'image-4',
        title: 'Platformă de comert electronic',
        visit:'https://maestoso-design.com'
    },
    {
        image: 'image-5',
        title: 'Platformă de comert electronic',
        visit:'https://honmatokyo.ro'
    },
    {
        image: 'image-6',
        title: 'Portal institutie publică',
        visit:'https://primariadoljesti.ro'
    },

]

class Portfolio extends Component{
    render(){
        let title = 'Ultimele proiecte',
        description = '';
        return(
            <React.Fragment>
                <div className="portfolio-wrapper"  d-flex align-items-center bg_image bg_image--2 >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title">
                                    <h2 style={{color: "#fff",textAlign:"center"}}>{title}</h2>
                                    <p style={{color: "#fff",textAlign:"center"}}>{description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...portfolioSlick2}>
                            {PortfolioList.map((value , visit, index) => (
                                <div className="portfolio" key={index}>
                                    <div className="thumbnail-inner">
                                        <div className={`thumbnail ${value.image}`}></div>
                                        <div className={`bg-blr-image ${value.image}`}></div>
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <h4 className="title">{value.title}</h4>
                                            <div className="portfolio-button">
                                                <a className="rn-btn" href={value.visit} target='{_blank}'>vezi platforma</a>
                                            </div>
                                        </div>
                                    </div>
                                  
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Portfolio;