import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import BrandTwo from "../elements/BrandTwo";
import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class About extends Component{
    render(){
        let title = 'Despre noi',
        description = 'Suntem o companie software cu e experientă de peste 10 ani în domeniul digital. Astăzi, construim, lansam și administrăm aplicatii web și mobile complexe mobile folosindu-ne de technologii precum GraphQL, React Native, Typescript sau Laravel framework. Solutiile noastre IT sunt rapide, scalabile, robuste și sigure. Mai exact, putem lucra pentru dvs. în proiecte precum: magazine online, platforme web complexe, software ERP/CRM sau site-uri de prezentare. Credem în parteneriate pe termen lung cu clientii noștri.';
     
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Despre' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Despre'}   />
                {/* End Breadcrump Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--30 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}
                
                {/* Start About Area  */}
                <div className="rn-about-area ptb--30 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">În cod ne încredem!</h2>
                                            <p className="description">Suntem o echipă care creează viitorul alături de colegi, clienți și parteneri. Credem cu tărie că comunicarea și informațiile exacte sunt foarte importante. Procesul nostru de dezvoltare este foarte simplu și bine definit - Rafinare Backlog, Sprint Review, Planning, întâlniri regulate. Veți avea acces deplin la GIT Repos pentru a măsura progresul nostru zilnic. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}
                 {/* Start About Area  */}
                 <div className="rn-about-area ptb--30 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">Tehnologi pe care le folosim</h2>
                                            <div className="brand-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <BrandTwo />
                                </div>
                            </div>
                        </div>
                    </div> 
                                             </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}
 {/* Start Brand Area */}
 
 <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500"></h3>
                                </div>
                            </div>
         <div className="rn-brand-area brand-separation ptb--60" >
                  
                </div>
                {/* End Brand Area */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default About